<template>
    <div class="Congress">
        <div>
            <div   class="pb-6 pb-300 diagonal pt-2 pt-md-7">
                <div class="row" >
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Kongresov za tento mesiac" v-if="loading == false"
                                    type="gradient-red"
                                    :sub-title="this.reservationThisMonth[11][0].countnid"
                                    icon="fas fa-star"
                                    class="mb-4 mb-xl-0"
                        >

                            <template slot="footer" v-if="loading == false">
                                <span :class="[this.reservationThisMonth[7] > 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[7] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[7]}} </span>
                                <span class="text-nowrap">Oproti minulému mesiacu</span>
                            </template>
                        </stats-card>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Dopyty za tento mesiac" v-if="loading == false"
                                    type="gradient-orange"
                                    :sub-title="this.reservationThisMonth[1] + ' / ' + this.reservationThisMonth[0] "
                                    icon="fas fa-check"
                                    class="mb-4 mb-xl-0"
                        >

                            <template slot="footer" v-if="loading == false">
                                <span :class="[this.reservationThisMonth[6] > 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[6] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[6]}} </span>
                                <span class="text-nowrap">Oproti minulému mesiacu</span>
                            </template>
                        </stats-card>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Súčet prenájmu priestorov" v-if="loading == false"
                                    type="gradient-green"
                                    :sub-title="this.reservationThisMonth[2][0].countnid"
                                    icon="far fa-meh"
                                    class="mb-4 mb-xl-0"
                        >

                            <template slot="footer" v-if="loading == false">
                                <span :class="[this.reservationThisMonth[8] < 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[8] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[8]}} </span>
                                <span class="text-nowrap">Oproti minulému mesiacu</span>
                            </template>
                        </stats-card>

                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Súčet schválených kongresov" v-if="loading == false"
                                    type="gradient-info"
                                    :sub-title="this.reservationThisMonth[3][0].countnid"
                                    icon="fas fa-level-down-alt"
                                    class="mb-4 mb-xl-0"
                        >

                            <template slot="footer" v-if="loading == false">
                                <span :class="[this.reservationThisMonth[9] < 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[9] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[9]}} </span>
                                <span class="text-nowrap">Oproti minulému mesiacu</span>
                            </template>
                        </stats-card>
                    </div>
                </div>
            </div>

            <!--Charts-->
            <div class="container-fluid" style="margin-top:-270px">
                <div class="row">
                    <div class="col-xl-9 mb-5 mb-xl-0">
                        <a-card type="white" header-classes="bg-transparent">
                            <div class="header row align-items-center">
                                <div class="col">
                                    <h6 class="text-light text-uppercase ls-1 mb-1">Prehľad</h6>
                                    <h5 class="h3 text-dark mb-0">Vývoj cien za rok 2020</h5>
                                </div>
                                <div class="col">
                                    <ul class="nav nav-pills justify-content-end">
                                        <li class="nav-item mr-2 mr-md-0">

                                        </li>
                                    </ul>
                                </div>
                            </div> 

                        </a-card>
                    </div>


                    <div class="col-xl-3">
                        <div class="col-xl-12 col-lg-12 mb-4 pl-0 pr-0">
                            <stats-card title="Najbližší kongres" v-if="loading == false"
                                        type="gradient-red"
                                        :sub-title="this.reservationThisMonth[2][0].countnid"
                                        icon="far fa-meh"
                                        class="mb-4 mb-xl-0"
                            >

                                <template slot="footer" v-if="loading == false">
                                    <span :class="[this.reservationThisMonth[8] < 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[8] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[8]}} </span>
                                    <span class="text-nowrap">Oproti minulému mesiacu</span>
                                </template>
                            </stats-card>

                        </div>
                        <div class="col-xl-12 col-lg-12 mb-4 pl-0 pr-0">
                            <stats-card title="Najviac využívaný priestor" v-if="loading == false"
                                        type="gradient-orange"
                                        :sub-title="this.reservationThisMonth[2][0].countnid"
                                        icon="far fa-meh"
                                        class="mb-4 mb-xl-0"
                            >

                                <template slot="footer" v-if="loading == false">
                                    <span :class="[this.reservationThisMonth[8] < 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[8] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[8]}} </span>
                                    <span class="text-nowrap">Oproti minulému mesiacu</span>
                                </template>
                            </stats-card>

                        </div>
                        <div class="col-xl-12 col-lg-12 mb-4 pl-0 pr-0">
                            <stats-card title="Najväčší počet hostí" v-if="loading == false"
                                        type="gradient-info"
                                        :sub-title="this.reservationThisMonth[2][0].countnid"
                                        icon="far fa-meh"
                                        class="mb-4 mb-xl-0"
                            >

                                <template slot="footer" v-if="loading == false">
                                    <span :class="[this.reservationThisMonth[8] < 0 ? 'text-success' : 'text-danger'] + ' mr-2'"><i class="fa fa-arrow-up" v-if="this.reservationThisMonth[8] > 0"/> <i class="fa fa-arrow-down" v-else></i>{{this.reservationThisMonth[8]}} </span>
                                    <span class="text-nowrap">Oproti minulému mesiacu</span>
                                </template>
                            </stats-card>

                        </div>
                 </div>
                </div>

                    <div class="row mt-5">
                        <div class="col-xl-5 mb-5 mb-xl-0">
                            <social-traffic-table :tableData="this.reservationThisMonth[10]"></social-traffic-table>
                        </div>
                        <div class="col-xl-7 mb-5 mb-xl-0">
                            <social-traffic-table :tableData="this.reservationThisMonth[10]"></social-traffic-table>
                        </div>
                    </div>
                </div>
                    <!-- End charts-->

                <!--Tables
                <div class="row mt-5">
                    <div class="col-xl-8 mb-5 mb-xl-0">
                        <page-visits-table></page-visits-table>
                    </div>
                    <div class="col-xl-4">
                        <social-traffic-table></social-traffic-table>
                    </div>
                </div>
               End tables-->
            </div>

        </div>
</template>

<script>
    // Charts
    import {dataService} from "../../_services"; 
  
    export default {
        components: { 
        },
        data() {
            return {
                message: "",
                loading: true,
                reservationThisMonth: "",
                bigLineChart: {
                    extraOptions: {legend: {
                            display: true,
                            labels:
                                {
                                    fontColor: 'rgb(255, 255, 255)'
                                }
                        }},
                    activeIndex: 0,
                    chartData: {
                        datasets: [],
                        labels: [],
                    },
                },
                redBarChart: {
                    chartData: {
                    }
                }
            };
        },
        methods: {
            getMonths() {
                var date = new Date();
                var months = [],
                    monthNames = [ "Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sep", "Okt", "Nov", "Dec" ];
                for(var i = 13; i >= 0; i--) {
                    months.push(monthNames[date.getMonth()]);
                    date.setMonth(date.getMonth() - 1);
                }

                return months.reverse();;
            },
            initBigChart(index) {
                let chartData = {
                    datasets: [
                        {
                            label: 'Nacenené dopyty',
                            borderColor: '#bce7f4',
                            backgroundColor: 'rgba(188,231,244,.6)',
                            data: this.reservationThisMonth[5]
                        },
                        {
                            label: 'Schválené kongresy',
                            borderColor: '#f4afac',
                            backgroundColor: 'rgba(244,131,105,0.2)',
                            data: this.reservationThisMonth[4]
                        },
                    ],
                    labels: this.getMonths(),
                };

                let chartData2 = {
                    labels: this.getMonths(),
                    datasets: [{
                        label: 'Celkové predaje cez Web',
                        data: this.reservationThisMonth[5]
                    }]
                };

                this.bigLineChart.chartData = chartData;
                this.bigLineChart.activeIndex = index;
                this.redBarChart.chartData = chartData2;

            }
        },
        mounted() {
            const comp = this;
            dataService.axiosFetch("wellnessstat").then(results => {
                comp.reservationThisMonth = results;
                this.initBigChart(0);
                this.loading = false;
            });

        }
    };
</script>

<style>

    .header.diagonal:after{
        content: " ";
        display:inline-block;
        height: 100px;
        width: 100%;
        background-color: #f8f9fe;
        position: absolute;
        bottom: -50px;
        transform: skew(0deg, -3deg);
        box-shadow: 11px -12px 12px -15px #9c9c9c;
    }
</style>
